import React from "react";
import { Link } from "react-router-dom";
import BackArrow from "../../assets/images/back-arrow.svg";
// import { InputGroup, Button } from "react-bootstrap";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import { useSnackbar } from "notistack";
// import * as Yup from "yup";
// import LockIcon from "../../assets/images/lock-password.svg";
import BankIcon from "../../assets/images/bankIcon.svg";
// import Service from "../../shared/_services/inventory.service";
// import UserContext from "../../context/userContext";
// import { confirm } from "react-confirm-box";
import "./Settings.css";

const Settings = () => {
  // const [defaultPriceInitialValues, setDefaultPriceInitialValues] = useState({
  //   pricePerWorker: "",
  //   pricePerInventoryStaff: "",
  //   pricePerHybridUser: "",
  // });

  // const [showPassword, setShowPassword] = useState(false);
  // const [showNewPassword, setNewPassword] = useState(false);
  // const [showConformPassword, setShowConformPassword] = useState(false);

  // const { enqueueSnackbar } = useSnackbar();
  // const user = useContext(UserContext);
  // const accessToken = user?.id;
  // const navigate = useNavigate();

  // const initialValues = {
  //   currentPassword: "",
  //   newPassword: "",
  //   confirmPassword: "",
  // };

  // const DefaultPriceValidationSchema = Yup.object().shape({
  //   pricePerWorker: Yup.number()
  //     .required("Price per Worker is required")
  //     .positive("Price should be a positive number"),
  //   pricePerInventoryStaff: Yup.number()
  //     .required("Price per Inventory Staff is required")
  //     .positive("Price should be a positive number"),
  //   pricePerHybridUser: Yup.number()
  //     .required("Price per Hybrid User is required")
  //     .positive("Price should be a positive number"),
  // });

  // useEffect(() => {
  //   if (accessToken) {
  //     Service.getDefaultPrice(enqueueSnackbar, accessToken).then((response) => {
  //       if (response.status === 200) {
  //         const { pricePerWorker, pricePerInventoryStaff, pricePerHybridUser } =
  //           response.data;

  //         setDefaultPriceInitialValues({
  //           pricePerWorker,
  //           pricePerInventoryStaff,
  //           pricePerHybridUser,
  //         });
  //       }
  //     });
  //   }
  // }, [accessToken, enqueueSnackbar]);

  // useEffect(() => {}, [defaultPriceInitialValues]);

  // const validationSchema = Yup.object().shape({
  //   currentPassword: Yup.string().required("Current Password is required"),

  //   newPassword: Yup.string()
  //     .required("New Password is required")
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=[\]{};:'",./<>?\\|]).{8,}$/,
  //       "Password should contain at least 1 number, 1 lowercase, 1 uppercase, 1 special character, and must be at least 8 characters long."
  //     ),
  //   confirmPassword: Yup.string()
  //     .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
  //     .required("Confirm Password is required")
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=[\]{};:'",./<>?\\|]).{8,}$/,
  //       "Password should contain at least 1 number, 1 lowercase, 1 uppercase, 1 special character, and must be at least 8 characters long."
  //     ),
  // });

  // const handleDefaultPriceSubmit = async (values, { isSubmitting }) => {
  //   const result = await confirm(
  //     "Do you want to update default prices?",
  //     options
  //   );
  //   if (result) {
  //     const data = {
  //       pricePerWorker: values.pricePerWorker,
  //       pricePerInventoryStaff: values.pricePerInventoryStaff,
  //       pricePerHybridUser: values.pricePerHybridUser,
  //     };

  //     const response = await Service.updateDefaultPrices(
  //       data,
  //       enqueueSnackbar,
  //       accessToken
  //     );

  //     if (response.status === 200) {
  //       let variant = "success";
  //       enqueueSnackbar(response.message, { variant });
  //       navigate("/");
  //     } else {
  //       let variant = "error";
  //       enqueueSnackbar(response.message, { variant });
  //     }
  //   }
  // };

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   // Handle password change logic here
  //   const result = await confirm(
  //     "Do you want to change the password?",
  //     options
  //   );

  //   if (result) {
  //     const data = {
  //       currentPassword: values.currentPassword,
  //       newPassword: values.newPassword,
  //       confirmNewPassword: values.confirmPassword,
  //     };

  //     const response = await Service.changePassword(
  //       data,
  //       enqueueSnackbar,
  //       accessToken
  //     );

  //     if (response.status === 200) {
  //       let variant = "success";
  //       enqueueSnackbar(response.message, { variant });
  //       navigate("/");
  //       setSubmitting(false);
  //     } else {
  //       let variant = "error";

  //       enqueueSnackbar(response.response.data.message, { variant });
  //       navigate("/settings");
  //     }
  //   }
  // };

  // const options = {
  //   render: (message, onConfirm, onCancel) => {
  //     return (
  //       <div className="conform_modal">
  //         <h3>Are you Sure?</h3>
  //         <p>{message}</p>
  //         <div className="conform_box_btn">
  //           <button className="cancel_btn" onClick={onCancel}>
  //             Cancel
  //           </button>
  //           <button className="conform_btn" onClick={onConfirm}>
  //             Confirm
  //           </button>
  //         </div>
  //       </div>
  //     );
  //   },
  // };

  return (
    <div className="home_content settings">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/">
                <img src={BackArrow} alt="back arrow" />
              </Link>
              <h4>Settings</h4>
            </div>
          </div>
        </div>

        {/* <Formik
          initialValues={defaultPriceInitialValues}
          validationSchema={DefaultPriceValidationSchema}
          onSubmit={handleDefaultPriceSubmit}
          enableReinitialize={true}
        >
          {(formikProps) => (
            <Form>
              <div className="white-box-sec mt-3">
                <div className="row">
                  <div className="col-12">
                    <h5>Set Default Price</h5>
                  </div>
                </div>
                <div className="row set_price">
                  <div className="col-lg-4">
                    <div className="form-group pb-2">
                      <label className="input-label">Price per Worker</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          type="text"
                          name="pricePerWorker"
                          placeholder="Enter Price per Worker"
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        name="pricePerWorker"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group pb-2">
                      <label className="input-label">
                        Price per Inventory Staff
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          type="text"
                          name="pricePerInventoryStaff"
                          placeholder="Enter Price per Inventory Staff"
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        name="pricePerInventoryStaff"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group pb-2">
                      <label className="input-label">
                        Price per Hybrid User
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <Field
                          type="text"
                          name="pricePerHybridUser"
                          placeholder="Enter Price per Hybrid User"
                          className="form-control"
                        />
                      </div>
                      <ErrorMessage
                        name="pricePerHybridUser"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-sm-6">
                    <div className="cancel-btn">
                      <Link to="/">
                        <button>Cancel</button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="submit-btn">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik> */}
        <div className="white-box-sec mt-3">
          <div className="row">
            <div className="col-6">
              <div className="d-flex bank-acc align-items-center">
                <img
                  src={BankIcon}
                  style={{ width: "40px" }}
                  alt="company-table"
                />
                <p className="m-0 px-2">
                  <b>Bank Account</b>
                </p>
              </div>
            </div>
            <div className="col-6">
              <button className="add-bank-acc">Add Bank Account</button>
            </div>
          </div>
        </div>
        {/* <div className="white-box-sec mt-3  mb-5 change-password">
          <div className="row mb-4">
            <div className="col-6">
              <div className="d-flex bank-acc align-items-center">
                <img src={LockIcon} style={{ width: "40px" }} alt="lock icon" />
                <p className="m-0 px-2">
                  <b>Change Password</b>
                </p>
              </div>
            </div>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, isValid, touched }) => (
              <Form>
                <div className="changePassword">
                  <div className="form-group pb-1">
                    <label className="input-label">
                      Current Password<span className="required">*</span>
                    </label>
                    <InputGroup className="password">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="currentPassword"
                        placeholder="Enter Current Password"
                        className="form-control form-control-cus"
                        autoComplete="current-password"
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        className="eye_icon"
                      >
                        {showPassword ? (
                          <i className="fas fa-eye"></i>
                        ) : (
                          <i className="fas fa-eye-slash"></i>
                        )}
                      </span>
                    </InputGroup>
                    <ErrorMessage
                      name="currentPassword"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group pb-1">
                    <label className="input-label">
                      New Password<span className="required">*</span>
                    </label>
                    <InputGroup>
                      <Field
                        type={showNewPassword ? "text" : "password"}
                        name="newPassword"
                        placeholder="Enter New Password"
                        className="form-control form-control-cus"
                        autoComplete="newPassword"
                      />
                      <span
                        onClick={() => setNewPassword(!showNewPassword)}
                        className="eye_icon"
                      >
                        {showNewPassword ? (
                          <i className="fas fa-eye"></i> // Show password icon
                        ) : (
                          <i className="fas fa-eye-slash"></i> // Hide password icon
                        )}
                      </span>
                    </InputGroup>
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="form-group pb-1">
                    <label className="input-label">
                      Confirm Password<span className="required">*</span>
                    </label>
                    <InputGroup>
                      <Field
                        type={showConformPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Enter Confirm Password"
                        className="form-control form-control-cus"
                        autoComplete="confirmPassword"
                      />
                      <span
                        onClick={() =>
                          setShowConformPassword(!showConformPassword)
                        }
                        className="eye_icon"
                      >
                        {showConformPassword ? (
                          <i className="fas fa-eye"></i> // Show password icon
                        ) : (
                          <i className="fas fa-eye-slash"></i> // Hide password icon
                        )}
                      </span>
                    </InputGroup>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error-message"
                    />
                  </div>
                </div>
                <div className="row my-3">
                  <div className="col-sm-6">
                    <div className="cancel-btn">
                      <Link to="/">
                        <button>Cancel</button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="submit-btn">
                      <Button
                        type="submit"
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          !Object.keys(touched).length
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div> */}
      </div>
    </div>
  );
};
export default Settings;
