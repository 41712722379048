import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ chartData, chartType }) => {
  const yAxisTitle = chartType === "Sales" ? "Sales" : "Revenue";
  const lineColor = chartType === "Sales" ? "#FF0000" : "#275598";
  const options = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 3,
      colors: [lineColor],
    },
    xaxis: {
      categories: chartData.labels,
      labels: {
        style: {
          colors: "#000",
        },
      },
      title: {
        text: "Month",
        style: {
          color: "#000",
          fontWeight: 400,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(2); // Customize the label formatting here
        },
        style: {
          colors: "#000", // Color of Y-axis labels
        },
      },
      title: {
        text: yAxisTitle,
        style: {
          color: "#000",
          fontWeight: 400,
          fontSize: "12px",
        },
      },
    },
  };

  const series = [
    {
      name: chartData.seriesName,
      data: chartData.data,
    },
  ];

  return (
    <div className="line-chart">
      <Chart options={options} series={series} type="line" height={300} />
    </div>
  );
};

export default LineChart;
