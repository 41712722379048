// StatusDropdown.js

import React, { useState } from "react";
import { Modal, Dropdown, FormControl, FormLabel } from "react-bootstrap";
import "./SupportIssues.css";
const StatusDropdown = ({ currentStatus, onStatusUpdate, selectedStatus }) => {
  const [showModal, setShowModal] = useState(false);
  const [newStatus, setNewStatus] = useState(currentStatus);
  const [note, setNote] = useState("");

  const handleStatusChange = (status) => {
    setNewStatus(status);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNote("");
  };

  const handleModalSubmit = () => {
    onStatusUpdate(newStatus, note);
    setShowModal(false);
    setNote("");
  };
  const statusColors = {
    onHold: "On Hold",
    closed: "Closed",
    inProgress: "In Progress",
    solved: "Solved",
  };

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle className={currentStatus}>
          {statusColors[currentStatus]}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleStatusChange("onHold")}>
            On Hold
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleStatusChange("closed")}>
            Closed
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleStatusChange("inProgress")}>
            In Progress
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleStatusChange("solved")}>
            Solved
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle">Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-3">
          <FormLabel>Add Note:</FormLabel>
          <FormControl value={note} onChange={(e) => setNote(e.target.value)} />
        </Modal.Body>
        <Modal.Footer className="mt-4">
          <botton className="cancel_btn" onClick={handleModalClose}>
            Close
          </botton>
          <botton className="conform_btn" onClick={handleModalSubmit}>
            Submit
          </botton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StatusDropdown;
