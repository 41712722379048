import "./App.css";
import React, { useState } from "react";
import InventoryDashboard from "./component/Dashboard/Dashboard";
import Company from "./component/Company/company";
import SupportIssues from "./component/SupportIssues/SupportIssues";
import { Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "./component/not-found/not-found";
import UserLayout from "./layout/user-layout/user-layout";
import ProtectedRoute from "./routes/private-routes";
import Login from "./component/login/Login";
import CompanyDetails from "./component/Company/CompanyDetails/companyDetails";
import Settings from "./component/Settings/Settings";
import ForgotPassword from "./component/login/ForgotPassword";
import VerifyEmail from "./component/login/verifyEmail";
import SetNewPassword from "./component/login/SetNewPassword";
import AddCompany from "./component/Company/addCompany/AddCompany";
import EditCompany from "./component/Company/editCompany/EditCompany";
import AddUser from "./component/Company/addUser/addUser";

import UserContext from "./context/userContext";
import EditUser from "./component/Company/editUser/editUser";
import BillingDetails from "./component/Company/billing-details/billing-details";

function App() {
  const navigate = useNavigate();
  const userStorage = JSON.parse(localStorage.getItem("currentUser"));
  const [user, setUser] = useState(userStorage);

  const handleLogout = (user) => {
    localStorage.removeItem("currentUser");
    setUser(null);
    navigate("/login");
  };

  const handleLogin = (user) => {
    console.log(user);
    setUser(user);
  };

  return (
    <div className="App">
      <UserContext.Provider value={user}>
        <Routes>
          <Route path="/login" element={<Login handleLogin={handleLogin} />} />
          <Route path="/forget-password" element={<ForgotPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/set-new-password" element={<SetNewPassword />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <UserLayout handleLogout={handleLogout}>
                  <InventoryDashboard />
                </UserLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/support-issues"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <SupportIssues />
                </ProtectedRoute>
              </UserLayout>
            }
          />

          <Route
            path="/company/:id/company-details"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <CompanyDetails />
                </ProtectedRoute>
              </UserLayout>
            }
          />
          <Route
            path="/company/:id/company-details/billing-details"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <BillingDetails />
                </ProtectedRoute>
              </UserLayout>
            }
          />

          <Route
            path="/company"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <Company />
                </ProtectedRoute>
              </UserLayout>
            }
          />
          <Route
            path="/company/add-new-company"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <AddCompany />
                </ProtectedRoute>
              </UserLayout>
            }
          />
          <Route
            path="/company/:id/edit-company"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <EditCompany />
                </ProtectedRoute>
              </UserLayout>
            }
          />
          <Route
            path="/company/company-details/add-user"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <AddUser />
                </ProtectedRoute>
              </UserLayout>
            }
          />
          <Route
            path="/company/company-details/edit-user"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <EditUser />
                </ProtectedRoute>
              </UserLayout>
            }
          />
          <Route
            path="/settings"
            element={
              <UserLayout handleLogout={handleLogout}>
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              </UserLayout>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </UserContext.Provider>
    </div>
  );
}

export default App;
