import { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../context/userContext";

function ProtectedRoute({ children }) {
  const user = useContext(UserContext);

  // const navigate = useNavigate();

  return user !== null ? <>{children}</> : <Navigate to="/login" replace />;
}

export default ProtectedRoute;
