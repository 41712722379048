import HandleResponse from "../_helpers/HandleResponse";
import Constants from "../_helpers/constants";
import AuthHeader from "../_helpers/authHeaders";
import { BehaviorSubject } from "rxjs";
import axios from "axios";

const currentUserSubject = new BehaviorSubject(
  localStorage.getItem("currentUser")
);

const login = async (email, password) => {
  const url =
    Constants.BASE_URL_CLIENT +
    `/api/APIv1AuthenticateUser?email=${email}&password=${password}`;
  // const data = { email, password };
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, { headers });
    const user = response;

    if (response.status === 200) {
      localStorage.setItem("currentUser", JSON.stringify(user.data));
      currentUserSubject.next(user);
      return user;
    } else {
      return { error: user }; // Return the error object for handling
    }
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};
const setNewPassword = async (email, password, newPassword, userID) => {
  const url = Constants.BASE_URL + "/api/super-admin/set-new-password";
  const headers = AuthHeader();
  const data = { email, password, newPassword, userID };

  try {
    const response = await axios.post(url, data, { headers });
    const user = HandleResponse(response);
    return user;
  } catch (error) {
    throw error;
  }
};

const requestForgotPassword = async (email) => {
  const url = Constants.BASE_URL + "/api/super-admin/forgot-password";
  const data = { email };
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    const result = HandleResponse(response);
    return result;
  } catch (error) {
    throw error;
  }
};

const verifyEmail = async (email, OTP) => {
  const url = Constants.BASE_URL + "/api/super-admin/verify-otp";
  const data = { email, OTP };
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    const result = HandleResponse(response);
    return result;
  } catch (error) {
    throw error;
  }
};

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  window.location.replace("/login");
};

const AuthenticationService = {
  login,
  logout,
  requestForgotPassword,
  setNewPassword,
  verifyEmail,
};

export default AuthenticationService;
