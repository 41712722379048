import React from "react";
import { useLocation, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import BackArrow from "../../../assets/images/back-arrow.svg";
import invoice from "../../../assets/images/file-invoice-dollar.svg";

const BillingDetails = ({ loading }) => {
  const location = useLocation();
  const result = location.state?.billingData || [];
  const CanoID = location.state?.CanoID || "";
  const data = result?.data;

  const BillingColumns = [
    {
      name: "Invoice ID",
      width: "450px",
      selector: (row) => (
        <div>
          <img src={invoice} alt="Profile" />
          <span className="mx-2">{row?.id || "-"}</span>
        </div>
      ),
    },

    {
      name: "Date",
      selector: (row) => row?.created || "-",
      sortable: true,
      width: "220px",
    },
    {
      name: "Amount ($)",
      selector: (row) => {
        if (row && row?.amount_received !== undefined) {
          return row?.amount_received / 100;
        }
        return "";
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => row?.description || "-",
      sortable: true,
    },
  ];

  return (
    <div className="home_content inner-home-content itemDetails">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to={`/company/${CanoID}/company-details`}>
                <img src={BackArrow} alt="arrow" />
              </Link>
              <h4>Billing Details ({CanoID})</h4>
            </div>
          </div>
        </div>
        <div className="row mt-sm-4 mt-xs-0 mb-5">
          <div className="col-lg-12 ">
            <div className="align-left company_details">
              {!loading && (
                <DataTable
                  columns={BillingColumns}
                  data={data}
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 15]}
                  subHeader
                  subHeaderAlign="left"
                  progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center align-items-center">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "skyblue" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingDetails;
