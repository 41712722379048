import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Login.css";
import Login_bg from "../../assets/images/login-bg.jpg";
// import AuthenticationService from "../../shared/_services/authenticationServices";
import Logo from "../../assets/images/logo.png";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Login = ({ handleLogin }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleAuthentication = async ({ email, password }) => {
    const lowerCaseEmail = email.toLowerCase();
    const lowerCasePassword = password.toLowerCase();

    if (
      lowerCaseEmail === "scano@canoconsultinginc.com" &&
      lowerCasePassword === "br549"
    ) {
      localStorage.setItem(
        "currentUser",
        JSON.stringify([{ email: lowerCaseEmail }])
      );
      handleLogin([{ email: lowerCaseEmail }]);
      navigate("/");
    } else {
      let variant = "error";
      enqueueSnackbar(
        "Invalid credentials. Ensure your email and password are correct.",
        { variant }
      );
    }
  };

  const initialValues = {
    email: "",
    password: "",
    // remember: rememberMe,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    handleAuthentication(values);
    setSubmitting(false);
  };

  return (
    <div
      className="login"
      id="login"
      style={{ backgroundImage: `url(${Login_bg})` }}
    >
      <Container fluid>
        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div className="content-mid">
              <div className="mng-login-des">
                <div className="login-bg">
                  <div className="login-logo text-center">
                    <img src={Logo} className="img-fluid" alt="CANO LOGO" />
                  </div>
                  <div className="welcome-text mt-3">
                    <h5>Welcome to Cano API</h5>
                    <p>Sign In to your Account</p>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting, values }) => (
                      <Form>
                        <div className="form-group pb-2 form-style">
                          <label className="input-label">
                            Email<span className="required">*</span>
                          </label>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="form-control form-control-cus"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group pb-1">
                          <label className="input-label">
                            Password<span className="required">*</span>
                          </label>
                          <div className="password-input">
                            <Field
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder="Password"
                              className="form-control form-control-cus"
                            />
                            <div
                              className="toggle-password"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <i className="fas fa-eye"></i>
                              ) : (
                                <i className="fas fa-eye-slash"></i>
                              )}
                            </div>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <Field
                              type="checkbox"
                              name="remember"
                              className="form-check-input mt-0"
                              // onClick={() => setRememberMe(!values.remember)}
                            />
                            <span className="mx-2 ">Remember Me</span>
                          </div>
                        </div>

                        <div className="text-center pt-4">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="primary-btn signin"
                          >
                            {isSubmitting ? "Signing in..." : "Sign in"}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
