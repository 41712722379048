import React from "react";

function NotFound() {
  return (
    <div
      style={{
        height: "80vh",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#fff",
        margin: "20px",
      }}
    >
      <h1>Page Not Found</h1>
    </div>
  );
}

export default NotFound;
