import React, { useEffect, useState } from "react";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import SearchIcon from "../../assets/images/Search.svg";
import InventoryService from "../../shared/_services/inventory.service";
import { useSnackbar } from "notistack";

import supportIcon from "../../assets/images/supportIcon.svg";
import DataTable from "react-data-table-component";
import StatusDropdown from "./statusCell";
import "./SupportIssues.css";

const SupportIssues = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [supportIssue, setSupportIssue] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [canoAccount, setCanoAccount] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  function escapeRegExp(input) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  const capitalizeFirstLetter = (string) => {
    const formattedString = string.replace(/([a-z])([A-Z])/g, "$1 $2"); // Add space between camelCase
    return formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
  };

  const handleStatusUpdate = (
    itemId,
    CompanyName,
    Type,
    newStatus,
    note,
    Subject,
    Description
  ) => {
    setSupportIssue((prevSupportIssues) =>
      prevSupportIssues?.map((issue) => {
        if (issue.id === itemId) {
          const newAction = {
            Status: newStatus,
            Action: note,
            DTS: new Date().toISOString(),
          };

          // Append the new CorrectiveActions object to the existing array
          const updatedCorrectiveActions = [
            ...issue.CorrectiveActions,
            newAction,
          ];

          // Update the specific support ticket with the new CorrectiveActions array
          const updatedIssue = {
            ...issue,
            Status: newStatus,
            CorrectiveActions: updatedCorrectiveActions,
          };

          // Update the support ticket in the service
          InventoryService.updateTicketStatus(updatedIssue, newStatus)
            .then((response) => {
              if (response.data.status === "success") {
                setSelectedStatus(newStatus);
                const formattedStatus = capitalizeFirstLetter(newStatus);

                enqueueSnackbar(
                  `The status changed to ${formattedStatus} successfully.`,
                  {
                    variant: "success",
                  }
                );
              } else {
                enqueueSnackbar("Failed to update support ticket.", {
                  variant: "error",
                });
              }
            })
            .catch((error) => {
              console.error("Error updating support ticket:", error);
              enqueueSnackbar(
                "An error occurred while updating the support ticket.",
                {
                  variant: "error",
                }
              );
            });

          return updatedIssue;
        }

        return issue;
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    InventoryService.getAllCanoAccount()
      .then((response) => {
        if (!response?.error) {
          setCanoAccount(response);
          setLoading(false);
        } else {
          enqueueSnackbar(response.message, {
            variant: "error",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching Cano accounts:", error);
        enqueueSnackbar("An error occurred while fetching Cano accounts.", {
          variant: "error",
        });
        setLoading(false);
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (canoAccount.length === 0) return; // Wait for Cano accounts to be fetched
    setLoading(true);
    Promise.all(
      canoAccount.map((account) =>
        InventoryService.getSupportIssues(account.id)
          .then((response) => {
            if (!Array.isArray(response)) {
              return []; // If response is not an array, return an empty array
            }
            return response;
          })
          .catch((error) => {
            console.error("Error fetching support issues:", error);
            enqueueSnackbar(
              "An error occurred while fetching support issues.",
              { variant: "error" }
            );
            return [];
          })
      )
    )
      .then((issuesLists) => {
        // Flatten the array of arrays into a single array of support issues
        const supportIssues = issuesLists.flat();
        setSupportIssue(supportIssues);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching support issues:", error);
        enqueueSnackbar("An error occurred while fetching support issues.", {
          variant: "error",
        });
        setLoading(false);
      });
  }, [enqueueSnackbar, canoAccount]);

  useEffect(() => {
    if (supportIssue) {
      const escapedSearch = escapeRegExp(search);
      const resultData = supportIssue?.filter((support) => {
        return support?.Subject?.toLowerCase().match(
          new RegExp(escapedSearch, "i")
        );
      });
      setFilteredData(resultData);
    }
  }, [search, supportIssue]);

  const customSelector = (row) => {
    const text = row?.Description;
    const truncatedText =
      text?.length > 10 ? `${text?.substring(0, 10)}...` : text;

    const tooltip = (
      <Tooltip id={`tooltip-${row?.id}`} className="custom-tooltip">
        {text}
      </Tooltip>
    );

    return (
      <OverlayTrigger overlay={tooltip} placement="bottom">
        <span>{truncatedText}</span>
      </OverlayTrigger>
    );
  };

  const columns = [
    {
      width: "500px",
      name: "Ticket ID",
      cell: (row) => (
        <div className="custom-cell">
          <img src={supportIcon} alt="Profile" />
          <span className="mx-2">{row?.id}</span>
        </div>
      ),
    },

    {
      name: "Company Code",
      selector: (row) => row?.CompanyName || "-",
      sortable: true,
    },

    {
      name: "Subject",
      selector: (row) => row?.Subject || "-",
      sortable: true,
    },
    {
      name: "Description",
      selector: customSelector,
      sortable: true,
    },

    {
      name: "Status",
      width: "300px",
      selector: (row) => row?.Status || "-", 
      sortable: true,
      cell: (row) => (
        <StatusDropdown
          currentStatus={row?.Status}
          selectedStatus={selectedStatus}
          className="dp_button"
          onStatusUpdate={(newStatus, note) =>
            handleStatusUpdate(
              row.id,
              row.CompanyName,
              row.Type,
              newStatus,
              note,
              row.Description,
              row.Subject
            )
          }
        />
      ),
    },
  ];

  return (
    <div className="container-fluid align-left inventory-count support-issues">
      <div className="page-heading">
        <h4 className="align-left top_head">Support Issues</h4>
      </div>

      <DataTable
        columns={columns}
        data={search ? filteredData : supportIssue}
        pagination
        subHeader
        subHeaderComponent={
          <div className="d-flex justify-content-between w-100 search_head">
            <div className="input-group w-50">
              <img src={SearchIcon} className="search-icon" alt="search" />

              <input
                type="text"
                placeholder="Search by subject"
                className="form-control w-25"
                style={{
                  borderRadius: "40px",
                  paddingLeft: "40px",
                  height: "45px",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        }
        subHeaderAlign="left"
        progressPending={loading}
        progressComponent={
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "skyblue" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      />
    </div>
  );
};

export default SupportIssues;
