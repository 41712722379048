// export default Navbar;
import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import "popper.js";
import "bootstrap/dist/js/bootstrap";
import logo from "../../assets/images/dashboardLogo.svg";
import logout from "../../assets/images/log-out.svg";

// import settingIcon from "../../assets/images/settings.svg";
// import UserContext from "../../context/userContext";

import { Modal } from "react-bootstrap";
const Navbar = ({ toggleSidebar, handleLogout }) => {
  // const currentUser = useContext(UserContext);
  // const userName = currentUser?.FName;
  const [isOpen, setIsOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
    toggleSidebar(!isOpen);
  };

  const onLogout = () => {
    setShowModal(true);
  };

  const handleConfirmLogout = () => {
    handleLogout(null);

    setShowModal(false);
  };

  const handleCancelLogout = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "20px",
          boxShadow: "#eeeeee 2px 0px 0px 1px",
          position: "relative",
        }}
      >
        <div
          className="top-section"
          style={{
            minWidth: isOpen ? "220px" : "70px",
            display: "flex",
            justifyContent: isOpen ? "inherit" : "center",
            transition: "all 0.5s",
          }}
        >
          <div className="bars">
            <FaBars onClick={toggle} className="bar" />
          </div>
          <img
            alt="Logo"
            className="logo"
            style={{ display: isOpen ? "block" : "none" }}
            src={logo}
          />
        </div>
        <div className="nav-user align-items-center">
          <div className="user-welcome">
            <p className="m-0">Hi, Welcome Steve!</p>
          </div>
          <div className="user-welcome">
            <p className="m-0"></p>
          </div>

          <div className="truck-logout justify-content-end">
            {/* <div className="dropdown me-2">
              <Link to="/settings">
                <img src={settingIcon} alt="setting" className="pt-1" />
              </Link>
            </div> */}
            <img
              src={logout}
              alt="logout"
              className="icon-color me-4"
              style={{ color: "#FF4E00" }}
              onClick={onLogout}
            />
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCancelLogout}>
        <Modal.Header>
          <h5 style={{ fontWeight: "600", marginBottom: "0!important" }}>
            Sign Out
          </h5>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to sign out?</p>
        </Modal.Body>

        <Modal.Footer>
          <button className="cancel_btn" onClick={handleCancelLogout}>
            Cancel
          </button>

          <button className="conform_btn" onClick={handleConfirmLogout}>
            Sign Out
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Navbar;
