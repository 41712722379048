import React, { useState } from "react";
import Navbar from "../navigation/navigation";
import Sidebar from "../sidebar/sidebar";
import Main from "../main/main";

const UserLayout = ({ children, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar
        toggleSidebar={toggleSidebar}
        handleLogout={handleLogout}
        isOpen={isOpen}
      />
      <div className={`wrapper ${isOpen ? "sidebar-open" : ""}`}>
        <Sidebar isOpen={isOpen} />
        <Main>{children}</Main>
      </div>
    </>
  );
};

export default UserLayout;
