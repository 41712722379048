import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Login.css";
import Login_bg from "../../assets/images/login-bg.jpg";
import AuthenticationService from "../../shared/_services/authenticationServices";
import Logo from "../../assets/images/logo.png";
import { useSnackbar } from "notistack";
import { useNavigate, Link } from "react-router-dom";
// import UserContext from "../../context/userContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const CurrentUser = useContext(UserContext);

  const handleAuthentication = async ({ email }) => {
    try {
      const user = await AuthenticationService.requestForgotPassword(email);

      if (user?.status === 400) {
        let variant = "error";
        enqueueSnackbar(user?.message, { variant });
      } else if (!user?.error) {
        let variant = "success";
        enqueueSnackbar(user.message, { variant });
        navigate("/verify-email", { state: { email: email } });
        // setIsOpenBtn(false);
      }
    } catch (error) {
      console.log(error);
      let variant = "error";
      enqueueSnackbar(error && error.response.data.message, { variant });
      //   setIsOpenBtn(true);
    }
  };

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    handleAuthentication(values);
    setSubmitting(false);
  };
  // const renderForm = (
  return (
    <div
      className="login"
      id="login"
      style={{ backgroundImage: `url(${Login_bg})` }}
    >
      <Container fluid>
        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div className="content-mid">
              <div className="mng-login-des">
                <div className="login-bg">
                  <div className="login-logo text-center">
                    <img src={Logo} className="img-fluid" alt="CANO LOGO" />
                  </div>
                  <div className="welcome-text mt-3">
                    <h5>Forgot Password</h5>
                    <p>
                      Please enter your registered email address to reset
                      password
                    </p>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="form-group pb-2 form-style">
                          <label className="input-label">
                            Email<span className="required">*</span>
                          </label>
                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="form-control form-control-cus"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message"
                          />
                        </div>

                        <div className="row my-5">
                          <div className="col-sm-6">
                            <div className="cancel-btn">
                              <Link to="/login">
                                <button>Cancel</button>
                              </Link>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="submit-btn">
                              <button type="submit">Submit</button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
