import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Login.css";
import Login_bg from "../../assets/images/login-bg.jpg";
import AuthenticationService from "../../shared/_services/authenticationServices";
import Logo from "../../assets/images/logo.png";
import { useSnackbar } from "notistack";
import { useNavigate, Link, useLocation } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const userID = location.state?.userId;
  const email = location.state?.email;

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleAuthentication = async ({ newPassword, password }) => {
    try {
      const response = await AuthenticationService.setNewPassword(
        email,
        password,
        newPassword,
        userID
      );

      if (response?.status === 400) {
        let variant = "error";
        enqueueSnackbar(response?.message, { variant });
      } else if (response.status === 200) {
        let variant = "success";
        enqueueSnackbar(response.message, { variant });
        navigate("/login");
      } else {
        throw new Error(response.error.message);
      }
    } catch (error) {
      console.error(error);
      let variant = "error";
      enqueueSnackbar(error.response.data.message, { variant });
    }
  };

  const initialValues = {
    newPassword: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=[\]{};:'",./<>?\\|]).{8,}$/,
        "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits."
      ),
    password: Yup.string()
      .required("Confirm Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=[\]{};:'",./<>?\\|]).{8,}$/,
        "Password should contain atleast 1 number, 1 lowercase, 1 uppercase, 1 special character and must of 8 digits."
      ),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    handleAuthentication(values);
    setSubmitting(false);
  };

  return (
    <div
      className="login"
      id="login"
      style={{ backgroundImage: `url(${Login_bg})` }}
    >
      <Container fluid>
        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div className="content-mid">
              <div className="mng-login-des">
                <div className="login-bg">
                  <div className="login-logo text-center">
                    <img src={Logo} className="img-fluid" alt="CANO LOGO" />
                  </div>
                  <div className="welcome-text mt-3">
                    <h5>Reset Password</h5>
                    <p>
                      Password must include at least 8 characters including 1
                      number and 1 special character
                    </p>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="form-group pb-2 form-style">
                          <label className="input-label">
                            New Password<span className="required">*</span>
                          </label>
                          <div className="password-input">
                            <Field
                              type={showNewPassword ? "text" : "password"}
                              name="newPassword"
                              placeholder="New Password"
                              className="form-control form-control-cus"
                            />
                            <div
                              className="toggle-password"
                              onClick={() =>
                                setShowNewPassword(!showNewPassword)
                              }
                            >
                              {showNewPassword ? (
                                <i className="fas fa-eye"></i>
                              ) : (
                                <i className="fas fa-eye-slash"></i>
                              )}
                            </div>
                          </div>
                          <ErrorMessage
                            name="newPassword"
                            component="div"
                            className="error-message"
                          />
                        </div>
                        <div className="form-group pb-1">
                          <label className="input-label">
                            Confirm New Password
                            <span className="required">*</span>
                          </label>
                          <div className="password-input">
                            <Field
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder="Confirm Password"
                              className="form-control form-control-cus"
                            />
                            <div
                              className="toggle-password"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <i className="fas fa-eye"></i>
                              ) : (
                                <i className="fas fa-eye-slash"></i>
                              )}
                            </div>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="error-message"
                          />
                        </div>

                        <div className="row my-5">
                          <div className="col-sm-6">
                            <div className="cancel-btn">
                              <Link to="/forget-password">
                                <button>Cancel</button>
                              </Link>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="submit-btn">
                              <button type="submit">Submit</button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SetNewPassword;
