import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import moment from "moment";
import BackArrow from "../../../assets/images/back-arrow.svg";
import eyeSlashIcon from "../../../assets/images/slash-eye-icon.svg";
import eyeIcon from "../../../assets/images/eye.svg";
import UserContext from "../../../context/userContext";
import Service from "../../../shared/_services/inventory.service";
import { confirm } from "react-confirm-box";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";

const initialValues = {
  CanoID: "",
  CompanyName: "",
  CompanyCode: "",
  databaseName: "",
  url: "",
  GUID: "",
  AuthID: "",
  databaseURL: "",
  databasePassword: "",
  databaseUser: "",
  SAPURL: "",
  SAPClientID: "",
  SAPRefreshToken: "",
  SAPURL2: "",
  SAPClientSecret: "",
  TypeOfProduct: "",
  StripePaymentStatus: "",
  StripePaymentCharged: "",
  PricePerWorker: 0,
  PricePerInventoryStaff: 0,
  PricePerHybridUser: 0,
  isTrial: false,
  TrialDays: 0,
  JoiningDate: "",
  Disabled: "",
  RestrictedCompanies: [],
};

const EditCompany = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(eyeSlashIcon);
  const [isTrialOn, setIsTrialOn] = useState(false); // State to track toggle button
  const [isChecked, setIsChecked] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const user = useContext(UserContext);
  const accessToken = user?.id;
  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();
  const { id } = useParams();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setPasswordIcon(showPassword ? eyeSlashIcon : eyeIcon);
  };

  const handleTrialToggle = () => {
    setIsTrialOn(!isTrialOn); // Toggle the state when the button is clicked
  };

  useEffect(() => {
    Service.getCompanyById(id, enqueueSnackbar).then((response) => {
      if (!response.error) {
        setCompanyData(response);

        const {
          id,
          CompanyName,
          CompanyCode,
          databaseName,
          TypeOfProduct,
          url,
          AuthID,
          connectionString,
          databaseURL,
          databasePassword,
          databaseUser,
          SAPURL,
          SAPClientID,
          SAPRefreshToken,
          SAPURL2,
          SAPClientSecret,
          PricePerWorker,
          PricePerInventoryStaff,
          PricePerHybridUser,
          StripePaymentStatus,
          StripePaymentCharged,
          TrialDays,
          JoiningDate,
          Disabled,
          StripePaymentMethodID,
          StripeCustomerID,
          AllowMultiCompJobReq,
          RestrictedCompanies,
        } = response;

        initialValues.CanoID = id;
        initialValues.CompanyName = CompanyName;
        initialValues.CompanyCode = CompanyCode;
        initialValues.databaseName = databaseName;
        initialValues.TypeOfProduct = TypeOfProduct;
        initialValues.url = url;
        initialValues.AuthID = AuthID;
        initialValues.databaseURL = databaseURL;
        initialValues.databasePassword = databasePassword;
        initialValues.connectionString = connectionString;
        initialValues.databaseUser = databaseUser;
        initialValues.SAPURL = SAPURL;
        initialValues.SAPClientID = SAPClientID;
        initialValues.SAPRefreshToken = SAPRefreshToken;
        initialValues.SAPURL2 = SAPURL2;
        initialValues.SAPClientSecret = SAPClientSecret;
        initialValues.StripePaymentStatus = StripePaymentStatus;
        initialValues.StripePaymentCharged = StripePaymentCharged;
        initialValues.PricePerWorker = PricePerWorker;
        initialValues.PricePerInventoryStaff = PricePerInventoryStaff;
        initialValues.PricePerHybridUser = PricePerHybridUser;
        // initialValues.status = status;
        initialValues.TrialDays = TrialDays;
        initialValues.TrialEndsOn = moment(JoiningDate)
          .add(TrialDays, "days")
          .format("DD-MMM-YYYY");
        initialValues.JoiningDate = JoiningDate;
        initialValues.Disabled = Disabled;
        initialValues.StripeCustomerID = StripeCustomerID;
        initialValues.StripePaymentMethodID = StripePaymentMethodID;
        initialValues.RestrictedCompanies = RestrictedCompanies;
        setIsTrialOn(TrialDays);
        setIsChecked(AllowMultiCompJobReq);
      }
    });
  }, [id, enqueueSnackbar, accessToken]);

  const validationSchema = Yup.object().shape({
    CanoID: Yup.string().required("Cano ID  is required"),
    CompanyName: Yup.string().required("Cano Account Name is required"),
    CompanyCode: Yup.string().required("Cano Account Code is required"),
    databaseName: Yup.string().required("Email is required"),
    url: Yup.string()
      .required("URL is required")
      .matches(
        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
        "Invalid URL format. Please enter a valid URL."
      ),
    AuthID: Yup.string().required("Auth ID is required"),
    databaseURL: Yup.string().required("SQL URL is required"),
    databasePassword: Yup.string().required("SQL Password is required"),
    databaseUser: Yup.string().required("Database User is required"), // Added this line
    SAPURL: Yup.string(),
    SAPClientID: Yup.string(),
    SAPRefreshToken: Yup.string(),
    SAPURL2: Yup.string(),
    SAPClientSecret: Yup.string(),
    TypeOfProduct: Yup.string().required("Product Type is required"),

    PricePerWorker: Yup.number().required("Price per Worker is required"),
    PricePerInventoryStaff: Yup.number().required(
      "Price per Inventory Staff is required"
    ),
    PricePerHybridUser: Yup.number().required(
      "Price per Hybrid User is required"
    ),

    isTrial: Yup.boolean(),
    TrialDays: Yup.number().when("isTrial", {
      is: true,
      then: Yup.number().required(
        "Trial Days are required when Trial is enabled"
      ),
    }),
  });
  // Program data update button

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const result = await confirm(
        "Do you want to update Cano Account?",
        options
      );

      if (result) {
        const data = {
          ...values,
          // TrialDays: isTrialOn,
          AllowMultiCompJobReq: isChecked,
          connectionString: companyData.connectionString,
        };

        Service.EditCompany(id, data, enqueueSnackbar).then((response) => {
          if (response.status === "success") {
            let variant = "success";
            enqueueSnackbar(response.message.status, { variant });
            Navigate("/company");
          } else {
            let variant = "error";
            enqueueSnackbar(response.message, {
              variant,
            });
          }
        });
      }
    },
  });

  const options = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className="conform_modal">
          <h3>Are you Sure?</h3>
          <p> {message} </p>
          <div className="conform_box_btn">
            <button className="cancel_btn" onClick={onCancel}>
              Cancel
            </button>
            <button className="conform_btn" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      );
    },
  };
  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div className="page-heading d-flex">
            <Link to="/company">
              <img src={BackArrow} alt="back arrow" />
            </Link>
            <h4>Edit Cano Account</h4>
          </div>
        </Col>
      </Row>
      <Form onSubmit={formik.handleSubmit}>
        <div className="white-box-sec mt-3">
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Company Name"
                  // readOnly
                  name="CompanyName"
                  {...formik.getFieldProps("CompanyName")}
                  value={formik.values.CompanyName}
                />
                {formik.touched.CompanyName && formik.errors.CompanyName ? (
                  <div className="error-msg">{formik.errors.CompanyName}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>CanoID</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  placeholder="Enter API ConoID"
                  name="CanoID"
                  {...formik.getFieldProps("CanoID")}
                  value={formik.values.CanoID}
                />
                {formik.touched.CanoID && formik.errors.CanoID ? (
                  <div className="error-msg">{formik.errors.CanoID}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>API Company Code</Form.Label>
                <Form.Control
                  type="text"
                  // readOnly
                  placeholder="Enter API Company Code"
                  name="CompanyCode"
                  {...formik.getFieldProps("CompanyCode")}
                  value={formik.values.CompanyCode}
                />
                {formik.touched.CompanyCode && formik.errors.CompanyCode ? (
                  <div className="error-msg">{formik.errors.CompanyCode}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Database Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter databaseName "
                  name="databaseName"
                  {...formik.getFieldProps("databaseName")}
                />
                {formik.touched.databaseName && formik.errors.databaseName ? (
                  <div className="error-msg">{formik.errors.databaseName}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter URl"
                  name="url"
                  {...formik.getFieldProps("url")}
                />
                {formik.touched.url && formik.errors.url ? (
                  <div className="error-msg">{formik.errors.url}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Auth ID</Form.Label>
                <Form.Control
                  type="text"
                  // readOnly
                  name="AuthID"
                  placeholder="Enter Auth ID"
                  {...formik.getFieldProps("AuthID")}
                />
                {formik.touched.AuthID && formik.errors.AuthID ? (
                  <div className="error-msg">{formik.errors.AuthID}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Database URL</Form.Label>
                <Form.Control
                  // readOnly
                  type="text"
                  placeholder="Enter SQL URL"
                  name="databaseURL"
                  {...formik.getFieldProps("databaseURL")}
                />
                {formik.touched.databaseURL && formik.errors.databaseURL ? (
                  <div className="error-msg">{formik.errors.databaseURL}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-1">
                <Form.Label>Database Password</Form.Label>
                <div className="eye-icon">
                  <img
                    src={passwordIcon}
                    className="hide-pass"
                    alt="eye-icon"
                    onClick={togglePasswordVisibility}
                  />
                </div>
                <Form.Control
                  placeholder="SQL Password"
                  // readOnly
                  id="exampleInputPassword1"
                  type={showPassword ? "text" : "password"}
                  name="databasePassword"
                  {...formik.getFieldProps("databasePassword")}
                />
                {formik.touched.databasePassword &&
                  formik.errors.databasePassword ? (
                  <div className="error-msg">
                    {formik.errors.databasePassword}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Product Type</Form.Label>
                <Form.Control
                  as="select"
                  name="TypeOfProduct"
                  className="sel-product-type"
                  {...formik.getFieldProps("TypeOfProduct")}
                >
                  <option>Inventory</option>
                  <option>Mobile</option>
                  <option>Hybrid</option>
                </Form.Control>
                {formik.touched.TypeOfProduct && formik.errors.TypeOfProduct ? (
                  <div className="error-msg">{formik.errors.TypeOfProduct}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Database User</Form.Label>
                <Form.Control
                  // readOnly
                  type="text"
                  placeholder="Enter SQL URL"
                  name="databaseUser"
                  {...formik.getFieldProps("databaseUser")}
                />
                {formik.touched.databaseURL && formik.errors.databaseUser ? (
                  <div className="error-msg">{formik.errors.databaseUser}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="white-box-sec mt-3">
          <Row>
            <Col>
              <h6 className="set-price">SAP</h6>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>SAP URL</Form.Label>
                <Form.Control
                  // readOnly
                  type="text"
                  placeholder="Enter SQL URL"
                  name="SAPURL"
                  {...formik.getFieldProps("SAPURL")}
                />
                {formik.touched.SAPURL && formik.errors.SAPURL ? (
                  <div className="error-msg">{formik.errors.SAPURL}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>SAP Client ID</Form.Label>
                <Form.Control
                  // readOnly
                  type="text"
                  placeholder="Enter SAP Client ID"
                  name="SAPClientID"
                  {...formik.getFieldProps("SAPClientID")}
                />
                {formik.touched.SAPClientID && formik.errors.SAPClientID ? (
                  <div className="error-msg">{formik.errors.SAPClientID}</div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>SAP Client Secret</Form.Label>
                <Form.Control
                  // readOnly
                  type="text"
                  placeholder="Enter SAP Client Secret"
                  name="SAPClientSecret"
                  {...formik.getFieldProps("SAPClientSecret")}
                />
                {formik.touched.SAPClientSecret &&
                  formik.errors.SAPClientSecret ? (
                  <div className="error-msg">
                    {formik.errors.SAPClientSecret}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>SAP Refresh Token</Form.Label>
                <Form.Control
                  // readOnly
                  type="text"
                  placeholder="Enter SAP Refresh Token"
                  name="SAPRefreshToken"
                  {...formik.getFieldProps("SAPRefreshToken")}
                />
                {formik.touched.SAPRefreshToken &&
                  formik.errors.SAPRefreshToken ? (
                  <div className="error-msg">
                    {formik.errors.SAPRefreshToken}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>SAP URL2</Form.Label>
                <Form.Control
                  // readOnly
                  type="text"
                  placeholder="Enter SAP URL2"
                  name="SAPURL2"
                  {...formik.getFieldProps("SAPURL2")}
                />
                {formik.touched.SAPURL2 && formik.errors.SAPURL2 ? (
                  <div className="error-msg">{formik.errors.SAPURL2}</div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="white-box-sec mt-3 add_company_setprice">
          <Row>
            <Col>
              <h6 className="set-price">Set Price</h6>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Price per Worker</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter Price per Worker"
                    name="PricePerWorker"
                    {...formik.getFieldProps("PricePerWorker")}
                  />
                </div>
                {formik.touched.PricePerWorker &&
                  formik.errors.PricePerWorker ? (
                  <div className="error-msg">
                    {formik.errors.PricePerWorker}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Price per Inventory Staff</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter Price per Inventory Staff"
                    name="PricePerInventoryStaff"
                    {...formik.getFieldProps("PricePerInventoryStaff")}
                  />
                </div>
                {formik.touched.PricePerInventoryStaff &&
                  formik.errors.PricePerInventoryStaff ? (
                  <div className="error-msg">
                    {formik.errors.PricePerInventoryStaff}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group className="pb-2">
                <Form.Label>Price per Hybrid User</Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter Price per Hybrid User"
                    name="PricePerHybridUser"
                    {...formik.getFieldProps("PricePerHybridUser")}
                  />
                </div>
                {formik.touched.PricePerHybridUser &&
                  formik.errors.PricePerHybridUser ? (
                  <div className="error-msg">
                    {formik.errors.PricePerHybridUser}
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="white-box-sec mt-3">
          <Row>
            <Col lg={2}>
              <div className="d-flex mt-4">
                <h6 className="mr-2">Trial</h6>
                <Toggle
                  checked={isTrialOn}
                  onChange={handleTrialToggle}
                  disabled
                  icons={{
                    checked: (
                      <div style={{ display: "flex" }}>
                        <i className="fas fa-check-circle"></i>
                      </div>
                    ),
                    unchecked: (
                      <div style={{ display: "flex" }}>
                        <i className="fas fa-times-circle"></i>
                      </div>
                    ),
                  }}
                />
              </div>
            </Col>
            {isTrialOn && formik.values.TrialDays > 0 ? (
              // Render Days field only if the toggle is checked
              <>
                <Col lg={3}>
                  <Form.Group className="pb-2">
                    <Form.Label>Trial Days</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Enter No of Days"
                      name="TrialDays"
                      {...formik.getFieldProps("TrialDays")}
                    />
                    {formik.touched.TrialDays && formik.errors.TrialDays ? (
                      <div className="error-msg">{formik.errors.TrialDays}</div>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="pb-2">
                    <Form.Label>Trial Ends on</Form.Label>
                    <Form.Control
                      type="text"
                      name="TrialEnds"
                      disabled
                      {...formik.getFieldProps("TrialEndsOn")}
                    />
                  </Form.Group>
                </Col>
              </>
            ) : (
              ""
            )}

            <Col lg={5}>
              <Form.Group className="d-flex mt-4">
                <Form.Label>Allow Multi Company Job Requisition</Form.Label>
                <Form.Check
                  type="switch" // You can use 'checkbox' here for a checkbox instead
                  id="isActiveSwitch" // Unique ID for the input
                  label=""
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="button-footer new-comp my-5">
          <Row>
            <Col lg={6} className="col-6">
              <div className="cancel-btn">
                <Link to="/company">
                  <Button>Cancel</Button>
                </Link>
              </div>
            </Col>
            <Col lg={6} className="col-6">
              <div className="submit-btn float-right">
                <button type="submit" disabled={formik.isSubmitting}>
                  Submit
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Container>
  );
};

export default EditCompany;
