// import AuthenticationService from "../_services/authenticationServices";

const HandleResponse = (response) => {
  const data = response.data;

  if (!response.status >= 200 && response.status < 300) {
    const error = (data && data.message) || data.error || response.statusText;
    return Promise.reject(error);
  }

  return data;
};
export default HandleResponse;
