import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InventoryService from "../../shared/_services/inventory.service";
import RegisteredCompanies from "../../assets/images/companies-icon.svg";
import SupportIssue from "../../assets/images/support-icon.svg";
import TotalSubscriptions from "../../assets/images/total-sub-icon.svg";
import ActiveSubscriptions from "../../assets/images/active-sub-icon.svg";
import "./Dashboard.css";
import moment from "moment";
import LineChart from "../chart/LineChart";
import { useSnackbar } from "notistack";

const InventoryDashboard = () => {
  const [registeredCompanies, setRegisteredCompanies] = useState(0);
  const [supportIssue, setSupportIssue] = useState(0);
  const [trialCompanies, setTrialCompanies] = useState(0);
  const [activeSubscriptions, setActiveSubscriptions] = useState(0);
  const [allCustomerData, setAllCustomerData] = useState([]);
  const [allSubscriptionData, setAllSubscriptionData] = useState([]);
  const [canoAccount, setCanoAccount] = useState([]);

  const enqueueSnackbar = useSnackbar();

  useEffect(() => {
    InventoryService.getAllCanoAccount().then((response) => {
      if (!response?.error) {
        setRegisteredCompanies(response.length);
        setAllCustomerData(response);
        setCanoAccount(response);
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
        });
      }
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (canoAccount.length === 0) return; // Wait for Cano accounts to be fetched

    Promise.all(
      canoAccount.map((account) =>
        InventoryService.getSupportIssues(account.id)
          .then((response) => {
            if (!Array.isArray(response)) {
              return []; // If response is not an array, return an empty array
            }
            return response;
          })
          .catch((error) => {
            console.error("Error fetching support issues:", error);
            enqueueSnackbar(
              "An error occurred while fetching support issues.",
              { variant: "error" }
            );
            return [];
          })
      )
    )
      .then((issuesLists) => {
        // Flatten the array of arrays into a single array of support issues
        const supportIssues = issuesLists.flat();
        setSupportIssue(supportIssues.length);
      })
      .catch((error) => {
        console.error("Error fetching support issues:", error);
        enqueueSnackbar("An error occurred while fetching support issues.", {
          variant: "error",
        });
      });
  }, [enqueueSnackbar, canoAccount]);

  useEffect(() => {
    InventoryService.ActiveSubscriptions().then((response) => {
      if (!response.error) {
        let active = 0;
        let trial = 0;
        response.forEach((element) => {
          if (
            moment(element["JoiningDate"])
              .add(element["TrialDays"], "days")
              .format() > moment().format()
          ) {
            trial++;
          } else if (element.StripePaymentStatus.startsWith("pi")) {
            active++;
          }
        });
        setActiveSubscriptions(active);
        setTrialCompanies(trial);
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
        });
      }
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    InventoryService.getAllSubscriptionData().then((response) => {
      if (response) {
      } else {
        enqueueSnackbar("No subscription found", {
          variant: "error",
        });
      }
    });
  }, [enqueueSnackbar]);

  //billing api data
  useEffect(() => {
    InventoryService.getRevenueData().then((response) => {
      if (response) {
        setAllSubscriptionData(response?.data);
      } else {
        enqueueSnackbar("No subscription found", {
          variant: "error",
        });
      }
    });
  }, [enqueueSnackbar]);

  const saleformatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  // Map JoiningDate to the formatted date
  const formattedCustomerData = allCustomerData.map((item) => {
    const formattedDate = saleformatDate(item.JoiningDate);
    return { ...item, JoiningDate: formattedDate };
  });

  const groupedData = formattedCustomerData.reduce((acc, currentItem) => {
    const date = currentItem.JoiningDate;

    if (acc[date]) {
      acc[date]++;
    } else {
      acc[date] = 1;
    }
    return acc;
  }, {});

  // Sort the dates in descending order
  const sortedDates = Object.keys(groupedData).sort((a, b) => {
    const dateA = new Date(b.split("/").reverse().join("-"));
    const dateB = new Date(a.split("/").reverse().join("-"));
    return dateA - dateB;
  });

  const sortedCounts = sortedDates.map((date) => groupedData[date]);

  const salesGraph = {
    seriesName: "Sales",
    labels: sortedDates,
    data: sortedCounts,
  };

  // Function to format timestamp to MM/DD/YYYY
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Group prices by date

  const groupedPricesByDate = allSubscriptionData.reduce((acc, current) => {
    const date = formatDate(current.created);
    const price = current.amount_received / 100;
    const description = current.description;

    // Check if the description is "Card verification payment"
    if (description !== "Card verification payment") {
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(price);
    }

    return acc;
  }, {});

  // Extract dates and prices
  const subdates = Object.keys(groupedPricesByDate);
  const prices = subdates.map((date) => {
    const pricesForDate = groupedPricesByDate[date];
    return pricesForDate.reduce((sum, price) => sum + price, 0); // Sum of prices for the date
  });

  // Sort dates in descending order
  subdates.sort((a, b) => {
    const dateA = new Date(a.split("/").reverse().join("-"));
    const dateB = new Date(b.split("/").reverse().join("-"));
    return dateB - dateA;
  });

  const sortedPrices = subdates.map((date) => prices[subdates.indexOf(date)]);

  const revenueGraph = {
    seriesName: "Revenue",
    labels: subdates, // Use dates as labels
    data: sortedPrices, // Use sorted prices as data
  };

  return (
    <div className="home_content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-12 mb-1 p-2">
            <Link to="/company">
              <div className="p-4 dashboard-grid d-flex">
                <div className="left-box-grid">
                  <img
                    src={RegisteredCompanies}
                    className="img-fluid"
                    alt="vector"
                    draggable="false"
                  />
                </div>
                <div className="right-box-grid">
                  <span style={{ color: "#000" }}>{registeredCompanies}</span>
                  <p>Registered Companies</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 col-12 mb-1 p-2">
            <Link to="/support-issues">
              <div className="p-4 dashboard-grid d-flex">
                <div className="left-box-grid">
                  <img
                    src={SupportIssue}
                    className="img-fluid"
                    alt="vector"
                    draggable="false"
                  />
                </div>
                <div className="right-box-grid">
                  <span style={{ color: "#000" }}> {supportIssue}</span>
                  <p>Support Issues</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 col-12 mb-1 p-2">
            <div className="p-4 dashboard-grid d-flex">
              <div className="left-box-grid">
                <img
                  src={TotalSubscriptions}
                  className="img-fluid"
                  alt="vector"
                  draggable="false"
                />
              </div>
              <div className="right-box-grid">
                <span>{trialCompanies}</span>
                <p>Companies on Trial Period</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 mb-1 p-2">
            <div className="p-4 dashboard-grid d-flex">
              <div className="left-box-grid">
                <img
                  src={ActiveSubscriptions}
                  className="img-fluid"
                  alt="vector"
                  draggable="false"
                />
              </div>
              <div className="right-box-grid">
                <span>{activeSubscriptions}</span>
                <p>Active Subscriptions</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-6 col-12 mb-1 p-2">
            <div className="p-4 dashboard-grid">
              <p className="fw-bold">Sales Graph</p>
              <LineChart chartData={salesGraph} chartType="Sales" />
            </div>
          </div>
          <div className="col-lg-6 col-12 mb-1 p-2">
            <div className="p-4 dashboard-grid">
              <p className="fw-bold">Revenue Graph</p>
              <LineChart chartData={revenueGraph} chartType="Revenue" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDashboard;
