import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
// import { v4 as uuidv4 } from "uuid";

import axios from "axios";
import Constant from "../../../shared/_helpers/constants";
import BackArrow from "../../../assets/images/back-arrow.svg";
import "./addUser.css";

const AddEmployees = () => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [employeeList, setEmployeeList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);

  const [userType, setUserType] = useState("");
  const [adminAccess, setAdminAccess] = useState("N");
  const [userWarehouse, setUserWarehouse] = useState("");
  const [foundedEmployee, setFoundedEmployee] = useState({});
  const [savedEmployees, setSavedEmployees] = useState({});
  const [isWarehouseMessage, setIsWarehouseMessage] = useState("Loading...");
  const [confirmModal, setShowConfirmModal] = useState(false);
  const [barcodeAccess, setBarcodeAccess] = useState("N");
  const location = useLocation();
  const data = location.state?.companyData || [];

  // const CompanyCode = data.CompanyCode;
  const CanoID = data.id;

  useEffect(() => {
    const getUsers = async () => {
      try {
        const [empList, savedEmployees] = await Promise.all([
          axios.get(
            `${Constant.BASE_URL_CLIENT}/api/APIv1GetNewUsers?CanoID=${CanoID}`
          ),
          axios.get(
            `${Constant.BASE_URL_CLIENT}/api/APIv1GetCanoUsers?CanoID=${CanoID}`
          ),
        ]);
        // setEmployeeList(empList.data.data);
        const sortedData = empList.data.data.sort((a, b) =>
          a.Operator_Name.localeCompare(b.Operator_Name)
        );
        setEmployeeList(sortedData);
        setSavedEmployees(savedEmployees.data.map((obj) => obj.email));
      } catch (err) {
        console.error(err);
        enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
      }
    };
    getUsers();
  }, [CanoID, enqueueSnackbar]);
  //new stripe
  function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  }

  var getRemanningDays = function () {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    return time.getDate() > date.getDate()
      ? time.getDate() - date.getDate()
      : 0;
  };

  const handleEmployeeChange = async (e) => {
    // console.log(e.target.value);

    const foundedEmployee = employeeList.find(
      (emp) => emp?.Email_Address.trim() === e.value.trim()
    );
    console.log(foundedEmployee);

    if (foundedEmployee?.Employee_Code == null) {
      enqueueSnackbar("Employee Code is not available", { variant: "error" });
      setIsWarehouseMessage("No data available");
      setWarehouseList([]);

      setFoundedEmployee({});
    } else if (savedEmployees.includes(foundedEmployee?.Email_Address.trim())) {
      enqueueSnackbar("Employee already exists", { variant: "error" });
      setIsWarehouseMessage("No data available");
      setFoundedEmployee({});
      setWarehouseList([]);
    } else {
      setFoundedEmployee(foundedEmployee);
      setWarehouseList([]);
      try {
        const UsersCompany = await axios.get(
          `${
            Constant.BASE_URL_CLIENT
          }/api/APIv1GetCompaniesForEmail?CanoID=${CanoID}&Email=${foundedEmployee?.Email_Address.replaceAll(
            "+",
            "%2B"
          ).trim()}`
        );
        const UserCompArry = UsersCompany.data.data.map(
          (user) => user.Company_Code
        );
        let AllWarehousesData = [];
        const fetchPromises = UserCompArry.map((cc) => {
          return axios.get(
            `${Constant.BASE_URL_CLIENT}/api/APIv1GetWarehouseListByCompany?CanoID=${CanoID}&CompanyCode=${cc}`
          );
        });
        setIsWarehouseMessage("Loading");
        const responses = await Promise.all(fetchPromises);
        for (let i = 0; i < UserCompArry.length; i++) {
          const data = responses[i].data;
          if (data.status) {
            AllWarehousesData.push(...data.data);
          }
        }

        if (fetchPromises.length <= 1) {
          setIsWarehouseMessage("No data available");
        }
        return setWarehouseList(AllWarehousesData);
      } catch (err) {
        return err;
      }
    }
  };

  const handleConfirmSave = async () => {
    setShowConfirmModal(false);

    if (userType === "")
      return enqueueSnackbar(`Please select valid user type`, {
        variant: "error",
      });
    if (userWarehouse === "")
      return enqueueSnackbar(`Please select valid warehouse`, {
        variant: "error",
      });
    if (Object.keys(foundedEmployee).length === 0)
      return enqueueSnackbar(`Please select valid employee`, {
        variant: "error",
      });

    const { data } = await axios.post(
      `${Constant.BASE_URL_CLIENT}/api/APIv1GetCanoAccount`,
      { CanoID }
    );
    let PriceType =
      userType === "Tech"
        ? "PricePerWorker"
        : "PricePer" + userType.replace(" ", "");

    let subscriptionStatus = "";
    let companyTrialEnds = moment(data["JoiningDate"])
      .add(data["TrialDays"], "days")
      .format("YYYY-MM-DD");

    if (companyTrialEnds >= moment().format("YYYY-MM-DD")) {
      subscriptionStatus = moment(companyTrialEnds).format("DD-MM-YYYY");
      console.log("Trial period is active for company");
    } else if (data["StripePaymentMethodID"] !== "") {
      const saveSubscription = await axios.post(
        `${Constant.BASE_URL_CLIENT}/api/APIv1SaveStripePaymentIntentForUser`,
        {
          PaymentMethodID: data["StripePaymentMethodID"],
          CustomerID: data["StripeCustomerID"],
          Price: Math.round(
            (parseInt(data[PriceType]) / daysInThisMonth()) * getRemanningDays()
          ),
          Description: `Payment intent for adding ${foundedEmployee.Email_Address.trim()}`,
        }
      );
      if (saveSubscription.data.status === "failure") {
        return enqueueSnackbar(`${saveSubscription.data.message}`, {
          variant: "error",
        });
      }
      subscriptionStatus = moment().endOf("month").format("DD-MM-YYYY");
    } else {
      return enqueueSnackbar(`You need to add payment method`, {
        variant: "error",
      });
    }

    axios
      .post(`${Constant.BASE_URL_CLIENT}/api/APIv1SaveUser`, {
        id: foundedEmployee.Email_Address.trim(),
        CanoID: CanoID,
        email: foundedEmployee.Email_Address.trim(),
        AuthID: CanoID,
        CompanyCode: [userWarehouse.split("-")[0].trim()],
        DefaultCompany: userWarehouse.split("-")[0].trim(),
        EmployeeCode: foundedEmployee.Employee_Code.trim(),
        FName: foundedEmployee.Operator_Name.trim(),
        Phone: foundedEmployee.Phone.trim(),
        DefaultWarehouse: userWarehouse.split("-")[1].trim(),
        Password:
          "7b766c5976ea1be1a681b233145bcb4f16141fb31c952ac5011363372c4892c5",
        Disabled: "N",
        Job2Warehouse: "Y",
        Warehouse2Warehouse: "Y",
        InventoryCount: "Y",
        AdminPanel: adminAccess,
        UserType: userType,
        BarcodePanel: barcodeAccess,
        Secret: "",
        LName: "",
        Street: "",
        Street2: "",
        City: "",
        State: "",
        ZipCode: "",
        Country: "",
        Mobile: "",
        Fax: "",
        Website: "",
        StripeSubscriptionID: subscriptionStatus,
      })
      .then((response) => {
        enqueueSnackbar(`User saved successfully`, { variant: "success" });
        navigate(`/company/${CanoID}/company-details`);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
      });
  };

  return (
    <div className="home_content inner-home-content itemDetails">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to={`/company/${CanoID}/company-details`}>
                <img src={BackArrow} alt="arrow" />
              </Link>
              <h4>Employee Management</h4>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <div
              className=" py-2 px-3"
              style={{ borderRadius: "5px", background: "#FFFFFF" }}
            >
              <div className="row mx-1">
                <div className="col-lg-3 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>User Type</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setUserType(e.target.value);
                      }}
                    >
                      <option>Select User Type</option>
                      <option value="Inventory Staff">Inventory Staff</option>
                      <option value="Tech">Tech</option>
                      <option value="Hybrid User">Hybrid User</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-5 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>Employee</label>
                    <Select
                      placeholder="Select Employee"
                      onChange={handleEmployeeChange}
                      options={employeeList.map((emp) => ({
                        value: emp.Email_Address,
                        label: `${emp.Operator_Name} - (${emp.Email_Address})`,
                      }))}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>Warehouse</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setUserWarehouse(e.target.value);
                      }}
                    >
                      <option>Select Warehouse</option>
                      {warehouseList.length === 0 ? (
                        <option value="" disabled>
                          {isWarehouseMessage}
                        </option>
                      ) : (
                        warehouseList.map((warehouse) => {
                          return (
                            <option
                              value={
                                warehouse.Company_Code +
                                "-" +
                                warehouse.Warehouse_Code
                              }
                              key={
                                warehouse.Company_Code +
                                warehouse.Warehouse_Code
                              }
                            >
                              {warehouse.Company_Code +
                                "-" +
                                warehouse.Name.trim()}
                            </option>
                          );
                        })
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row mx-1" style={{ overflowX: "auto" }}>
                <div className="col-lg-2 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>Admin Access</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setAdminAccess(e.target.value);
                      }}
                    >
                      <option value="N">No</option>
                      <option value="Y">Yes</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2 col-12 my-2">
                  <div className="table-inner-content detail">
                    <label>Barcode Access</label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setBarcodeAccess(e.target.value);
                      }}
                    >
                      <option value="N">No</option>
                      <option value="Y">Yes</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-12">
            <div
              className="custom-table py-2 px-3"
              style={{ borderRadius: "5px", background: "#FFFFFF" }}
            >
              <div className="row px-2 d-flex justify-content-between">
                <div className="col-lg-auto col-md-6 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5>Employee Name</h5>
                    <p className="m-0">
                      {foundedEmployee?.Operator_Name || "-"}
                    </p>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5>Emp.ID</h5>
                    <p className="m-0">
                      {foundedEmployee?.Employee_Code || "-"}
                    </p>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5>Email ID</h5>
                    <p className="m-0">
                      {foundedEmployee?.Email_Address || "-"}
                    </p>
                  </div>
                </div>
                <div className="col-lg-auto col-md-6 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5>Contact No.</h5>
                    <p className="m-0">{foundedEmployee?.Phone || "-"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="cancel-btn">
                <button
                  onClick={() => navigate(`/company/${CanoID}/company-details`)}
                  style={{
                    opacity:
                      userType !== "" &&
                      userWarehouse !== "" &&
                      foundedEmployee.Employee_Code !== undefined
                        ? "1"
                        : "0.5",
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-6">
              <div className="submit-btn">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    setShowConfirmModal(true);
                  }}
                  style={{
                    opacity:
                      userType !== "" &&
                      userWarehouse !== "" &&
                      foundedEmployee.Employee_Code !== undefined
                        ? "1"
                        : "0.5",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <Modal
            show={confirmModal}
            onHide={(e) => {
              setShowConfirmModal(false);
            }}
            centered
          >
            <Modal.Header>
              <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>
                Are you Sure?
              </h6>
            </Modal.Header>
            <Modal.Body>
              <span style={{ fontSize: "14px" }}>
                Are you sure you want to register a new employee?
              </span>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="cancel_btn"
                onClick={(e) => {
                  setShowConfirmModal(false);
                }}
              >
                Cancel
              </button>
              <button className="conform_btn" onClick={handleConfirmSave}>
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddEmployees;
