import Constants from "../_helpers/constants";
import axios from "axios";

//get support issues
const getSupportIssues = async (CanoID) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1GetSupportTicket?CanoID=${CanoID}`,
    });
    return response.data;
  } catch (err) {
    if (err.response.statusText === "Unauthorized") {
      localStorage.removeItem("currentUser");
      window.location.reload();
    }
  }
};

//update support issues status

const updateTicketStatus = async (updatedIssue, newStatus) => {
  const {
    id,
    CanoID,
    CompanyName,
    Type,
    Subject,
    CorrectiveActions,
    DTS,
    Description,
    Status,
  } = updatedIssue;
  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1SaveSupportTicket?CanoID=${CanoID}`,
      data: {
        id,
        CanoID,
        CompanyName,
        Type,
        Subject,
        CorrectiveActions,
        DTS,
        Description,
        Status,
      },
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//Get All cano accounts
const getAllCanoAccount = async () => {
  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL_CLIENT + `/api/APIv1GetAllCanoAccounts`,
      headers: {},
    });
    return response.data;
  } catch (err) {
    if (err.response.statusText === "Unauthorized") {
      localStorage.removeItem("currentUser");
      window.location.reload(); //redirect("/login");
    }
  }
};

const getCanoAccountByCanoId = async (CanoID) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT + `/api/APIv1GetCanoAccount?CanoID=${CanoID}`,

      headers: {},
    });
    return response;
  } catch (err) {
    if (err.response.statusText === "Unauthorized") {
      localStorage.removeItem("currentUser");
      window.location.reload(); //redirect("/login");
    }
  }
};

//delete company
const deleteCompany = async (enqueueSnackbar, companyId, accessToken) => {
  try {
    const response = await axios({
      method: "delete",
      url: Constants.BASE_URL + `/api/super-admin/remove-company`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { companyID: companyId },
    });
    return response;
  } catch (err) {
    let variant = "error";
    const error = "something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};
//update company status
const updateCompanyStatus = async (
  enqueueSnackbar,
  companyId,
  newStatus,
  row
) => {
  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1SaveCanoAccount?CanoID=${companyId}`,

      data: {
        ...row,
        Disabled: newStatus,
      },
    });
    return response;
  } catch (err) {
    let variant = "error";
    const error = "something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};

//add new company

const addNewCompany = async (data, enqueueSnackbar, stripeCustomerId) => {
  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1SaveCanoAccount?CanoID=${data.CanoID}`,

      data: {
        id: data.CanoID,
        CompanyName: data.CompanyName,
        CompanyCode: data.CompanyCode,
        databaseName: data.databaseName,
        TypeOfProduct: data.TypeOfProduct,
        url: data.url,
        AuthID: data.AuthID,
        databaseURL: data.databaseURL,
        databasePassword: data.databasePassword,
        databaseUser: data.databaseUser,
        SAPURL: data.SAPURL,
        SAPClientID: data.SAPClientID,
        SAPRefreshToken: data.SAPRefreshToken,
        SAPURL2: data.SAPURL2,
        SAPClientSecret: data.SAPClientSecret,
        PricePerWorker: +data.PricePerWorker,
        PricePerInventoryStaff: +data.PricePerInventoryStaff,
        PricePerHybridUser: +data.PricePerHybridUser,
        StripeCustomerID: stripeCustomerId,
        StripePaymentMethodID: "",
        TrialDays: data.TrialDays,
        AllowMultiCompJobReq: data.AllowMultiCompJobReq,
        JoiningDate: data.JoiningDate,
        Disabled: data.Disabled,
        StripePaymentStatus: data.StripePaymentStatus,
        StripePaymentCharged : data.StripePaymentCharged,
        RestrictedCompanies: [],
      },
    });
    return response;
  } catch (err) {
    let variant = "error";
    const error = "something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};
//get company by id
const getCompanyById = async (id, enqueueSnackbar) => {
  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL_CLIENT + `/api/APIv1GetCanoAccount?CanoID=${id}`,
    });
    return response.data;
  } catch (err) {
    let variant = "error";
    const error = "Something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};
//edit company
const EditCompany = async (id, data, enqueueSnackbar) => {
  try {
    const response = await axios({
      method: "post",
      url: Constants.BASE_URL_CLIENT + `/api/APIv1SaveCanoAccount?CanoID=${id}`,

      data: {
        id: id,
        CompanyName: data.CompanyName,
        CompanyCode: data.CompanyCode,
        DatabaseName: data.databaseName,
        TypeOfProduct: data.TypeOfProduct,
        URL: data.url,
        AuthID: data.AuthID,
        connectionString: data.connectionString,
        DatabaseURL: data.databaseURL,
        DatabasePassword: data.databasePassword,
        DatabaseUser: data.databaseUser,
        SAPURL: data.SAPURL,
        SAPClientID: data.SAPClientID,
        SAPRefreshToken: data.SAPRefreshToken,
        SAPURL2: data.SAPURL2,
        SAPClientSecret: data.SAPClientSecret,
        PricePerWorker: +data.PricePerWorker,
        PricePerInventoryStaff: +data.PricePerInventoryStaff,
        PricePerHybridUser: +data.PricePerHybridUser,
        GUID: data.GUID,
        StripeCustomerID: data.StripeCustomerID,
        StripePaymentMethodID: data.StripePaymentMethodID,
        StripePaymentStatus: data.StripePaymentStatus,
        StripePaymentCharged : data.StripePaymentCharged,
        AllowMultiCompJobReq: data.AllowMultiCompJobReq,
        TrialDays: data.TrialDays,
        JoiningDate: data.JoiningDate,
        Disabled: data.Disabled,
        RestrictedCompanies: data.RestrictedCompanies,
      },
    });
    return response.data;
  } catch (err) {
    let variant = "error";
    const error = "something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};
//change password
const changePassword = async (
  { currentPassword, newPassword, confirmNewPassword },
  enqueueSnackbar,
  accessToken
) => {
  const data = { currentPassword, newPassword, confirmNewPassword };

  try {
    const response = await axios({
      method: "post",
      url: Constants.BASE_URL + `/api/super-admin/change-password`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    });
    return response.data;
  } catch (err) {
    let variant = "error";

    enqueueSnackbar(err.response.data.message, { variant });
    return err;
  }
};

const getDefaultPrice = async (enqueueSnackbar, accessToken) => {
  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL + `/api/super-admin/default-price`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (err) {
    let variant = "error";
    const error = "Something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};

//update default prices
const updateDefaultPrices = async (
  { pricePerWorker, pricePerInventoryStaff, pricePerHybridUser },
  enqueueSnackbar,
  accessToken
) => {
  const data = { pricePerWorker, pricePerInventoryStaff, pricePerHybridUser };

  try {
    const response = await axios({
      method: "patch",
      url: Constants.BASE_URL + `/api/super-admin/set-default-price`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data,
    });
    return response.data;
  } catch (err) {
    let variant = "error";

    enqueueSnackbar(err.response.data.message, { variant });
    return err;
  }
};

//GET WAREHOUSE LIST BY COMPANY
const getWarehouseListByCompany = async (enqueueSnackbar) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1GetWarehouseListByCompany?CanoID=SXC&CompanyCode=SXC`,
    });

    return response.data;
  } catch (err) {
    let variant = "error";
    const error = "something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};

//GET employee LIST BY COMPANY
const getEmployeeListByCompany = async (enqueueSnackbar, CanoID) => {
  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL_CLIENT + `/api/APIv1GetNewUsers?CanoID=${CanoID}`,
    });

    return response.data;
  } catch (err) {
    let variant = "error";
    const error = "something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};
const EditUser = async (data, enqueueSnackbar) => {
  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL_CLIENT + `/api/APIv1SaveUser?CanoID=${data.CanoID}`,

      data: {
        id: data.id,
        CanoID: data.CanoID,
        email: data.email,
        AuthID: data.AuthID,
        CompanyCode: data.CompanyCode,
        DefaultCompany: data.DefaultCompany,
        EmployeeCode: data.EmployeeCode,
        FName: data.FName,
        Phone: data.Phone,
        DefaultWarehouse: data.DefaultWarehouse,
        Password: data.Password,
        Secret: data.Secret,
        LName: data.LName,
        Street: data.Street,
        Street2: data.Street2,
        City: data.City,
        State: data.State,
        ZipCode: data.ZipCode,
        Country: data.Country,
        Mobile: data.Mobile,
        Fax: data.Fax,
        Website: data.Website,
        Disabled: data.Disabled,
        Job2Warehouse: data.Job2Warehouse,
        Warehouse2Warehouse: data.Job2Warehouse,
        InventoryCount: data.InventoryCount,
        AdminPanel: data.AdminPanel,
        BarcodePanel: data.BarcodePanel,
        UserType: data.UserType,
      },
    });
    return response;
  } catch (err) {
    let variant = "error";
    const error = "something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};
const AddNewUser = async (data, enqueueSnackbar) => {
  try {
    const response = await axios({
      method: "post",
      url:
        Constants.BASE_URL_CLIENT + `/api/APIv1SaveUser?CanoID=${data.CanoID}`,

      data: {
        id: data.id,
        CanoID: data.CanoID,
        email: data.email,
        AuthID: data.AuthID,
        CompanyCode: data.CompanyCode,
        DefaultCompany: data.DefaultCompany,
        EmployeeCode: data.EmployeeCode,
        FName: data.FName,
        Phone: data.Phone,
        DefaultWarehouse: data.DefaultWarehouse,
        Password: data.Password,
        Secret: data.Secret,
        LName: data.LName,
        Street: data.Street,
        Street2: data.Street2,
        City: data.City,
        State: data.State,
        ZipCode: data.ZipCode,
        Country: data.Country,
        Mobile: data.Mobile,
        Fax: data.Fax,
        Website: data.Website,
        Disabled: data.Disabled,
        Job2Warehouse: data.Job2Warehouse,
        Warehouse2Warehouse: data.Job2Warehouse,
        InventoryCount: data.InventoryCount,
        AdminPanel: data.AdminPanel,
        BarcodePanel: data.BarcodePanel,
        UserType: data.UserType,
      },
    });
    return response;
  } catch (err) {
    let variant = "error";
    const error = "something went wrong";
    enqueueSnackbar(error, { variant });
    return err;
  }
};

const getCanoUser = async (CanoID) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT + `/api/APIv1GetCanoUsers?CanoID=${CanoID}`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
//for cano account edit user
const getUser = async (Email, CanoID) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1GetUser?CanoID=${CanoID}&Email=${Email}`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//for cano account edit user
const getCanoID = async () => {
  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL_CLIENT + `/api/APIv1GetNextCanoID`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const saveStripeUser = async (CompanyName, Email) => {
  try {
    const response = await axios({
      method: "post",
      url: Constants.BASE_URL_CLIENT + `/api/APIv1SaveStripeCustomer`,
      data: {
        CompanyName: CompanyName,
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
const getSubscriptionCount = async () => {
  try {
    const response = await axios({
      method: "get",
      url: Constants.BASE_URL_CLIENT + `/api/APIv1GetStripeSubscriptionCount`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
const getInvoiceData = async (StripeCustomerID) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1GetStripeAllPaymentIntentForCustomer?CustomerID=${StripeCustomerID}`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getRevenueData = async () => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1GetStripeAllPaymentIntentForCustomer`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const getAllCustomerData = async () => {
  let limit = 1000;
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT +
        `/api//APIv1GetStripeAllCustomers?limit=${limit}`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
const getAllSubscriptionData = async () => {
  let limit = 1000;
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT +
        `/api/APIv1GetStripeAllSubscriptions?limit=${limit}`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const ActiveSubscriptions = async (CanoID) => {
  try {
    const response = await axios({
      method: "get",
      url:
        Constants.BASE_URL_CLIENT + `/api/APIv1GetAllCanoAccounts?Active=true`,
    });

    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const InventoryService = {
  getCanoAccountByCanoId,
  getAllCanoAccount,
  getSupportIssues,
  updateTicketStatus,
  deleteCompany,
  updateCompanyStatus,
  addNewCompany,
  EditCompany,
  getCompanyById,
  changePassword,
  getDefaultPrice,
  updateDefaultPrices,
  getWarehouseListByCompany,
  getEmployeeListByCompany,
  AddNewUser,
  getCanoUser,
  getUser,
  EditUser,
  getCanoID,
  saveStripeUser,
  getSubscriptionCount,
  getInvoiceData,
  getAllSubscriptionData,
  getAllCustomerData,
  ActiveSubscriptions,
  getRevenueData,
};
export default InventoryService;
