import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Login.css";
import Login_bg from "../../assets/images/login-bg.jpg";
import AuthenticationService from "../../shared/_services/authenticationServices";
import Logo from "../../assets/images/logo.png";
import { useSnackbar } from "notistack";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const email = location.state?.email;

  const otpRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const validationSchema = Yup.object().shape({
    otp1: Yup.string()
      .matches(/^\d$/, "Enter a valid digit")
      .required("All fields are required"),
    otp2: Yup.string()
      .matches(/^\d$/, "Enter a valid digit")
      .required("All fields are required"),
    otp3: Yup.string()
      .matches(/^\d$/, "Enter a valid digit")
      .required("All fields are required"),
    otp4: Yup.string()
      .matches(/^\d$/, "Enter a valid digit")
      .required("All fields are required"),
    otp5: Yup.string()
      .matches(/^\d$/, "Enter a valid digit")
      .required("All fields are required"),
    otp6: Yup.string()
      .matches(/^\d$/, "Enter a valid digit")
      .required("All fields are required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const otp = `${values.otp1}${values.otp2}${values.otp3}${values.otp4}${values.otp5}${values.otp6}`;

    try {
      const response = await AuthenticationService.verifyEmail(email, otp);

      if (response?.status === 400) {
        let variant = "error";
        enqueueSnackbar(response?.message, { variant });
      } else if (!response?.error) {
        let variant = "success";
        enqueueSnackbar(response.message, { variant });

        navigate("/set-new-password", {
          state: { userId: response.data.userID, email: email },
        });
      }
    } catch (error) {
      let variant = "error";
      enqueueSnackbar(error && error.response.data.message, { variant });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div
      className="login"
      id="login"
      style={{ backgroundImage: `url(${Login_bg})` }}
    >
      <Container fluid>
        <Row>
          <Col lg={5}></Col>
          <Col lg={7}>
            <div className="content-mid">
              <div className="mng-login-des">
                <div className="login-bg">
                  <div className="login-logo text-center">
                    <img src={Logo} className="img-fluid" alt="CANO LOGO" />
                  </div>
                  <div className="welcome-text mt-3">
                    <h5>Verify your email</h5>
                    <p>
                      We have sent you the validation code to the registered
                      email address
                    </p>
                  </div>

                  <Formik
                    initialValues={{
                      otp1: "",
                      otp2: "",
                      otp3: "",
                      otp4: "",
                      otp5: "",
                      otp6: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ isSubmitting, errors, touched, setFieldValue }) => (
                      <Form>
                        <div className="form-group pb-2 form-style">
                          <label className="input-label">
                            Enter OTP<span className="required">*</span>
                          </label>
                          <div className="d-flex">
                            {[1, 2, 3, 4, 5, 6].map((index) => (
                              <Field
                                key={index}
                                type="text"
                                name={`otp${index}`}
                                maxLength="1"
                                placeholder=""
                                className="form-control form-control-cus verify-no mx-1"
                                innerRef={otpRefs[index - 1]}
                                onChange={(e) => {
                                  setFieldValue(`otp${index}`, e.target.value);
                                  if (
                                    e.target.value &&
                                    e.target.value.length === 1
                                  ) {
                                    const nextIndex = (index % 6) + 1;
                                    otpRefs[nextIndex - 1].current.focus();
                                  }
                                }}
                              />
                            ))}
                          </div>

                          {Object.keys(touched).length > 0 &&
                            Object.keys(errors).length > 0 && (
                              <div className="error-message common-error-message">
                                All fields are required
                              </div>
                            )}
                        </div>

                        <div className="row my-5">
                          <div className="col-sm-6">
                            <div className="cancel-btn">
                              <Link to="/forget-password">
                                <button>Cancel</button>
                              </Link>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="submit-btn">
                              <button type="submit" disabled={isSubmitting}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VerifyEmail;
